<template>
  <div class="bind-alipay">
    <van-cell-group class="van-cell-group">
      <van-field  input-align="right" v-model="userName" label="姓名" placeholder="请输入您的姓名" />
    </van-cell-group>

    <van-cell-group class="van-cell-group">
      <van-field  input-align="right" v-model="aliAccount" label="支付宝登录号" placeholder="请输入支付宝账号" />
    </van-cell-group>
  </div>

  <div class="withdrawal-btn" @click="toWithdrawal">绑定</div>

</template>

<script>
export default {
  name: 'BindAlipay',
  data(){
    return{
      userName:'',
      aliAccount:''
    }
  },
  methods:{
    toWithdrawal(){
      if(this.aliAccount.length==0){
          this.$toast.fail('账号不能为空!');
          return;
      }
      if(this.userName.length==0){
          this.$toast.fail('姓名不能为空!');
          return;
      }
      this.axios.post('/app/bindAliAccount', {
            aliAccount:this.aliAccount,
            userName:this.userName
          }).then((response) => {
            this.$router.back();
            
          }).catch(function (error) {
              console.log(error);
          });
    }
}
}
</script>

<style scoped>
  .bind-alipay{
    padding: 15px 10px;
  }
  .bind-alipay img{
    width: 100%;
  }
  .bind-alipay .van-cell-group{
    margin: 10px 0;
  }
  .withdrawal-btn{
    width: 95%;
    height: 45px;
    line-height: 45px;
    margin: 15px auto 0;
    text-align: center;
    color: #FFFFFF;
    background: #00ef83;
    border-radius: 5px;
  }
</style>
